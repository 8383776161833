@keyframes slideRight {
  from { transform: translateX(0); }
  to { transform: translateX(100%); }
}

@keyframes slideLeft {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

.slide-right {
  animation: slideRight 0.3s forwards;
}

.slide-left {
  animation: slideLeft 0.3s forwards;
}